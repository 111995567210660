export default ({ store, $route }, inject) => {
  if (process.client) {
    const queryParams = new URLSearchParams(window.location.search);

    if (!queryParams.has('iframe')) {
      return;
    }

    const iframeQueryParams = queryParams.keys().filter(param => param.startsWith("iframe_"));

    const iframeParams = Array.from(iframeQueryParams).reduce(
      function (acc, val) {
        const unprefixedKey = val.replace('iframe_', '');

        return {
          ...acc,
          [unprefixedKey]: queryParams.get(val),
        };
      },
      {},
    );

    const iframeMode = {};

    if (iframeParams.sport) {
      iframeMode.sport = parseInt(iframeParams.sport);
    }

    store.commit('SET_IFRAME_MODE', iframeMode);
  }
};
